import React from "react";
import IconBxlReact from "../assets/react-icon.js";
import IconLaravel from "../assets/laravel-icon.js";
import IconBxlJavascript from "../assets/javascript-icon.js";
import IconHtml5 from "../assets/html-icon.js";
import IconLanguagePhp from "../assets/php-icon.js";
import IconSass from "../assets/sass-icon.js";
import IconMysql from "../assets/mysql-icon.js";
import IconBootstrap from "../assets/bootstrap-icon.js";
import IconMongodb from "../assets/mongodb-icon.js";
import IconGit from "../assets/git-icon.js";


// Custom cursor event handlers (unchanged)
const h1Enter = () => {
  document.dispatchEvent(
    new CustomEvent("cursorChange", { detail: "h1Hover" })
  );
};

const h1Leave = () => {
  document.dispatchEvent(
    new CustomEvent("cursorChange", { detail: "default" })
  );
};

const aEnter = () => {
  document.dispatchEvent(new CustomEvent("cursorChange", { detail: "aHover" }));
};

const aLeave = () => {
  document.dispatchEvent(
    new CustomEvent("cursorChange", { detail: "default" })
  );
};

const About = () => {
  const icons = [<IconBxlReact />, <IconLaravel />, <IconBxlJavascript />,<IconHtml5 />,<IconLanguagePhp />,<IconSass />,<IconBootstrap />,<IconMysql />,<IconMongodb />,<IconGit />];

  return (
    <div className="container">
      <h1
        className="mb-4 font-size-32"
        onMouseEnter={h1Enter}
        onMouseLeave={h1Leave}
      >
        About Me
      </h1>
      <div className="row align-items-center mb-5">
        <div className="col-lg-4 mb-4">
          <img
            className="w-100 about-image waypoint-animate animate-top delay-1"
            src="../images/about.jpg"
            alt="about-image"
            style={{ filter: "grayscale(50%)" }}
          />
        </div>
        <div className="col-lg-7 col-xl-offset-1">
          <p
            className="font-size-16 waypoint-animate animate-top delay-2"
            onMouseEnter={h1Enter}
            onMouseLeave={h1Leave}
          >
            Hey, my name is Haris Mourelatos, and I am a Web Developer with more than three years of experience. I specialize in creating dynamic and user-friendly websites, ensuring they are both visually appealing and functionally robust. My expertise spans a wide range of technologies, including React, Laravel, HTML, and MySQL. I am passionate about delivering high-quality digital solutions that meet clients' needs and exceed their expectations.
          </p>
          <a
            href="../Cv-Haris Mourelatos2024.pdf"
            target="_blank"
            className="underline-hover font-size-16 text-white text-capitalize waypoint-animate animate-top delay-3"
            onMouseEnter={aEnter}
            onMouseLeave={aLeave}
          >
            <span>resume</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-arrow-right arrow-icon-right"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
              />
            </svg>
          </a>
        </div>
      </div>
      <div className="underlined-animated waypoint-animate animate-line delay-1 mb-4"></div>
      <div className="row mb-5">
        <div className="col-md-4">
          <h2 className="waypoint-animate animate-top delay-2  text-capitalize">skill set</h2>
        </div>
        <div className="col-md-8">
          <div className="row">
            {icons.map((icon, index) => (
            <div className={`col-md-auto col-3 mb-4 waypoint-animate animate-fade   delay-${index + 1}`}
            key={index}>                
            {icon}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="underlined-animated waypoint-animate animate-line delay-2 mb-4"></div>
      <div className="row">
        <div className="col-md-4">
          <h2 className=" waypoint-animate animate-top delay-3 text-capitalize">publication</h2>
        </div>
        <div className="col-md-8">
          <h3 className="waypoint-animate animate-top delay-4">Online video sharing and revenues during the pandemic. Evidence from musical stream data
           </h3>
            <p className="waypoint-animate animate-top delay-5">Applied Economics Letters · Aug 10, 2022</p>

            <a
            href="https://www.tandfonline.com/doi/full/10.1080/13504851.2022.2110209?src="
            target="_blank"
            className="underline-hover font-size-16 text-white text-capitalize waypoint-animate animate-top delay-6"
            onMouseEnter={aEnter}
            onMouseLeave={aLeave}
          >
            <span className=" text-capitalize ">read more</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-arrow-right arrow-icon-right"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
              />
            </svg>
          </a>          
        </div>
      </div>
    </div>
  );
};

export default About;