import "./Contact.scss"; // Import CSS for styling
import React from "react";

const Contact = () => {
  const h1Enter = () => {
    document.dispatchEvent(new CustomEvent('cursorChange', { detail: 'h1Hover' }));
  };

  const h1Leave = () => {
    document.dispatchEvent(new CustomEvent('cursorChange', { detail: 'default' }));
  };

  const aEnter = () => {
    document.dispatchEvent(new CustomEvent('cursorChange', { detail: 'aHover' }));
  };

  const aLeave = () => {
    document.dispatchEvent(new CustomEvent('cursorChange', { detail: 'default' }));
  };
  return (
    <div id="contact">
      <div className="container ">
        <div className="row align-items-start">
          <div className="col-md-12">
            <h1>Contact</h1>
            <hr />
          </div>
          <div className="col-lg-6">
            <div className="mail d-flex align-items-center ">
              <h3 className="text-uppercase mb-0 contact-label">mail</h3>
              <a href="mailto:harismourer@gmail.com"  onMouseEnter={aEnter} onMouseLeave={aLeave} className="text-white font-size-24 underline-hover waypoint-animate animate-top">
              <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="bi bi-arrow-up-right arrow-icon "
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"
                    />
                  </svg><span>
                  harismourer@gmail.com 
                  </span>
               </a>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="social-media d-flex">       
                <h3 className="text-uppercase mb-0 contact-label">Social media</h3>
           <ul className="list-unstyled mb-0">
            <li className="waypoint-animate animate-top delay-1">
            <a href="https://www.instagram.com/harismourelatos/"  onMouseEnter={aEnter} onMouseLeave={aLeave} target="_blank" className="font-size-24  pr-3 text-white underline-hover text-capitalize">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="bi bi-arrow-up-right arrow-icon"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"
                    />
                  </svg>
                  instagram
                </a>
            </li>
        <li className="waypoint-animate animate-top delay-2">
        <a href="https://www.facebook.com/mourelatos.haris"  onMouseEnter={aEnter} onMouseLeave={aLeave} target="_blank" className="font-size-24 text-white underline-hover text-capitalize">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="bi bi-arrow-up-right arrow-icon"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"
                    />
                  </svg>
                  facebook
                </a>
        </li>
        <li className="waypoint-animate animate-top delay-3">
            <a href="https://www.linkedin.com/in/haris-mourelatos/"  onMouseEnter={aEnter} onMouseLeave={aLeave} target="_blank" className="font-size-24 pr-3 text-white underline-hover text-capitalize">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="bi bi-arrow-up-right arrow-icon"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"
                    />
                  </svg>
                  linkedin
                </a>
            </li>
           </ul> 
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
