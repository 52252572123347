export const ArrowUndo = () => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      enableBackground: "new 0 0 512 512",
    }}
    viewBox="0 0 512 512" width="32px" height="32px"
  >
    <style>{".st0{fill:#fff}"}</style>
    <g id="Layer_2">
      <path
        d="M174.54 81.85c0-8.84-7.16-16-16-16h-76.7c-8.84 0-16 7.16-16 16v76.69c0 8.84 7.16 16 16 16h76.69c8.84 0 16-7.16 16-16V81.85zm-32 60.69h-44.7V97.85h44.69v44.69zM217.66 174.54h76.69c8.84 0 16-7.16 16-16V81.85c0-8.84-7.16-16-16-16h-76.69c-8.84 0-16 7.16-16 16v76.69c0 8.83 7.16 16 16 16zm16-76.69h44.69v44.69h-44.69V97.85zM353.46 174.54h76.69c8.84 0 16-7.16 16-16V81.85c0-8.84-7.16-16-16-16h-76.69c-8.84 0-16 7.16-16 16v76.69c0 8.83 7.17 16 16 16zm16-76.69h44.69v44.69h-44.69V97.85zM174.54 217.65c0-8.84-7.16-16-16-16h-76.7c-8.84 0-16 7.16-16 16v76.69c0 8.84 7.16 16 16 16h76.69c8.84 0 16-7.16 16-16v-76.69zm-32 60.7h-44.7v-44.69h44.69v44.69zM201.66 294.35c0 8.84 7.16 16 16 16h76.69c8.84 0 16-7.16 16-16v-76.69c0-8.84-7.16-16-16-16h-76.69c-8.84 0-16 7.16-16 16v76.69zm32-60.7h44.69v44.69h-44.69v-44.69zM337.46 294.35c0 8.84 7.16 16 16 16h76.69c8.84 0 16-7.16 16-16v-76.69c0-8.84-7.16-16-16-16h-76.69c-8.84 0-16 7.16-16 16v76.69zm32-60.7h44.69v44.69h-44.69v-44.69zM158.54 337.46h-76.7c-8.84 0-16 7.16-16 16v76.69c0 8.84 7.16 16 16 16h76.69c8.84 0 16-7.16 16-16v-76.69c.01-8.83-7.16-16-15.99-16zm-16 76.69h-44.7v-44.69h44.69v44.69zM201.66 430.15c0 8.84 7.16 16 16 16h76.69c8.84 0 16-7.16 16-16v-76.69c0-8.84-7.16-16-16-16h-76.69c-8.84 0-16 7.16-16 16v76.69zm32-60.69h44.69v44.69h-44.69v-44.69zM337.46 430.15c0 8.84 7.16 16 16 16h76.69c8.84 0 16-7.16 16-16v-76.69c0-8.84-7.16-16-16-16h-76.69c-8.84 0-16 7.16-16 16v76.69zm32-60.69h44.69v44.69h-44.69v-44.69z"
        className="st0" fill="#fff"
      />
    </g>
  </svg>
  );
};