import * as React from "react";

function IconMongodb(props) {
  return (
    <svg
      viewBox="0 0 32 32"
      fill="currentColor"
      height="40px"
      width="40px"
      {...props}
    >
      <path
        fill="currentColor"
        d="M22.797 14.562a13.663 13.663 0 00-.407-2.297c-.625-2.369-1.666-4.637-3.134-6.603a16.58 16.58 0 00-1.294-1.532c-.476-.499-1.004-.957-1.336-1.578-.21-.393-.41-.791-.614-1.187-.003.124-.011.248-.011.371 0-.124.009-.248.011-.372l-.13-.327c-.022.075-.036.101-.036.129-.017.645-.383 1.083-.838 1.492-.512.46-.989.959-1.481 1.441.017.022.036.044.055.066-.019-.022-.038-.043-.055-.066-1.463 1.924-2.752 3.981-3.511 6.29a14.691 14.691 0 00-.517 2.056c-.259 1.481-.379 2.92-.296 4.42.046.829.191 1.645.407 2.448.785 2.917 2.379 5.336 4.558 7.392.405.382.842.729 1.265 1.093l.003-.011-.003.011.187.642.174.996.083 1.039c-.001.211-.01.423.003.633.003.054.074.104.113.156l.004-.006-.004.006.353.124.367.143a322.63 322.63 0 00-.063-.924l-.003-.91-.021.021.021-.021.127-1.386.092-.302.263-.467c.325-.262.674-.499.971-.79.536-.527 1.071-1.06 1.55-1.637a12.294 12.294 0 001.588-2.441c1.223-2.491 1.789-5.269 1.564-8.039l-.006-.074z"
      />
    </svg>
  );
}

export default IconMongodb;
