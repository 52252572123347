import React from "react";
// import { useState } from "react";
// import { useParams } from 'react-router-dom';
import ProjectSlider from "./ProjectSlider";
import "./Projects.scss";

const projectInfo = [
  {
    title: "Transformations",
    images: [
      "../images/projects/transformations/1.jpg",
      "../images/projects/transformations/2.jpg",
      "../images/projects/transformations/3.jpg",
    ],
    description:
      "<p>The very idea of metamorphoses was my driving force on this trip. My goal was not to simply record or document the journey from one place to the next but to capture and convey a condensed experience full of contrasts that narrates the transfiguration of a geophysical volume to an empty shell and the transformation of a geological phenomenon into history, architecture and art.</p>",
    slidesperview: 2,
  },

  {
    title: "Everything is the same but everything is different",
    images: [
      "../images/projects/everything-is-the-same-different/1.jpg",
      "../images/projects/everything-is-the-same-different/2.jpg",
      "../images/projects/everything-is-the-same-different/3.jpg",
      "../images/projects/everything-is-the-same-different/3.jpg",
    ],
    description:
      "<p>Thousands of refugees, more than 150,000 in 2016 attempted the dangerous trip to cross Aegean Sea towards the Greek Islands to reach western Europe.</p><p>Most of them arrived at the islands of Lesvos and Chios. Hundreds of them died, among them were many children.</p><p>The seascape scenery will not be the same anymore. Everything is the same but everything is different.</p><p>The feeling of the presence of the refugees is still strong, is around us.</p><p> The beachfront of Lesvos and Chios works like a magnet and attracts a lot of photographers and activists.</p><p>The local community became part of this situation by being there helping by all means.</p><p>It seems that society is more mature than before in similar circumstances.    </p><p>For me capturing the seascapes that thousands of refugees passed and drowned made me realize that everything is the same but everything is different.</p>",
    slidesperview: 3,
  },

  {
    title: "Into The Dark",
    images: [
      "../images/projects/into-the-dark/1.jpg",
      "../images/projects/into-the-dark/2.jpg",
      "../images/projects/into-the-dark/3.jpg",
    ],
    description:
      '<p>In an effort to defeat my fear of darkness, I begun a night journey with my camera (that lasted almost 1,5 years) through the woods. The result of this quest is a wide body of work which is dominated by a bright light on trees, branches and rocks as they aggressively pop up in front of the viewer.</p><p>Art critic Barry Schwabsky on a text on the German photographer Rut Blees Luxemburg describes how clarity of form in her work "is overwhelmed by shades, nuances and sometimes even the downright Old-Masterish haziness." The same way I wanted to transcend all the tension and the emotional load of my adventure into images that cover up my feelings and are characterized by formalistic integrity, tranquility and a dramatic chiaroscuro.</p><p>And while light plays a dominant role in the creation of the image, ultimately in the final work is equally important that which we do not see, namely darkness. This way "the camera allows ... a transformation. Something other than what you see in your daily experience ... Something which is there but perhaps can be sensed better than it can be seen."</p>',
    slidesperview: 1,
  },

  {
    title: "Blur Identities",
    images: [
      "../images/projects/blur-identities/1.jpg",
      "../images/projects/blur-identities/2.jpg",
      "../images/projects/blur-identities/3.jpg",
    ],
    description: "",
    slidesperview: 2,
  },

  {
    title: "Urban Symmetry",
    images: [
      "../images/projects/urban-symmetry/1.jpg",
      "../images/projects/urban-symmetry/2.jpg",
      "../images/projects/urban-symmetry/3.jpg",
    ],
    description: "",
    slidesperview: 1,
  },

  {
    title: "Crevice",
    images: [
      "../images/projects/crevice/1.jpg",
      "../images/projects/crevice/2.jpg",
      "../images/projects/crevice/3.jpg",
    ],
    description: "",
    slidesperview: 1,
  },
];

const Projects = (props) => {
  // const [activeProjectIndex, setActiveProjectIndex] = useState(0);

  // const handleClick = (index) => {
  //   setActiveProjectIndex(index);
  // };

  return (
    <div className="container-fluid project-page-content">
      <ProjectSlider selectedProjectIndex={1} projectInfo={projectInfo} />
    </div>
  );
};

export default Projects;
