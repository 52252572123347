import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Dropdown } from "bootstrap";
import "./Header.scss";
import MenuSidebar from "./sidebar";
import { ArrowUndo } from "../assets/menu-icon.js";

// Event handlers for cursor changes
const h1Enter = () => {
  document.dispatchEvent(new CustomEvent("cursorChange", { detail: "h1Hover" }));
};

const h1Leave = () => {
  document.dispatchEvent(new CustomEvent("cursorChange", { detail: "default" }));
};

const aEnter = () => {
  document.dispatchEvent(new CustomEvent("cursorChange", { detail: "aHover" }));
};

const aLeave = () => {
  document.dispatchEvent(new CustomEvent("cursorChange", { detail: "default" }));
};

const Header = () => {
  const location = useLocation();
  const navRef = React.useRef(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  useEffect(() => {
    const body = document.querySelector("body");
    const header = document.querySelector("header");
    const footer = document.querySelector("footer");

    // Adding scroll event listener
    const scrollHandler = () => {
      if (window.scrollY > 80) {
        header.classList.add("scrolled");
      } else {
        header.classList.remove("scrolled");
      }
    };

    window.addEventListener("scroll", scrollHandler);

    // Removing scroll event listener when component unmounts
    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  useEffect(() => {
    const projectDropdownItems = document.querySelectorAll(
      ".project-dropdown-toggle"
    );
    projectDropdownItems.forEach((item) => {
      new Dropdown(item);
    });
  }, []);

  const handleSidebarToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleNavLinkClick = () => {
    setIsSidebarOpen(false); // Close the sidebar when NavLink is clicked
  };

  return (
    <header>
      <div className="container">
        <div className="row header-row gx-0 justify-content-between align-items-center">
          <div className="col-auto">
            <div className="main-logo">
              <Link to="/" className="ls-1 lh-1 h4 mb-0  text-uppercase fw-bold" onMouseEnter={aEnter} onMouseLeave={aLeave}>
                <span>
                  Haris Mourelatos
                </span>
              </Link>
            </div>
          </div>
          <div className="col-auto">
            <div className="burger-menu">
              <NavLink
                to="#"
                className={({ isActive }) =>
                  (isActive ? "active" : "") + " nav-link"
                }
                onClick={handleSidebarToggle}
                onMouseEnter={aEnter}
                onMouseLeave={aLeave}
              >
                <ArrowUndo />
              </NavLink>
            </div>
            <div
              className={`sidebar-mobile-menu ${
                isSidebarOpen ? "show-sidebar-mobile-menu" : ""
              }`}
              ref={navRef}
            >
              <MenuSidebar handleNavLinkClick={handleNavLinkClick} />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
