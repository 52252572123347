import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, EffectFade } from "swiper/modules";
import parse from "html-react-parser";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./ImageGrid.scss";
import ArrowNextIcon from "../assets/sliderArrowNext";
import ArrowPreviousIcon from "../assets/sliderArrowPrev";
import "swiper/css/effect-fade";

const ProjectSlider = (props) => {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  return (
    <div className="project-row row gx-0 way-point animate-fade">
      <div className="col-lg-4">
        <div className="slider-content d-flex justify-content-between flex-column h-100">
          <div className="content-text">
            <h2 className="mb-4">
              {props.projectInfo[props.selectedProjectIndex].title}
            </h2>
            <div className="project-description">
              {parse(props.projectInfo[props.selectedProjectIndex].description)}
            </div>
          </div>
        </div>
      </div>
      <div className="offset-lg-1 col-lg-7  h-100 position-relative">
        <div className="swiper-navigation">
          <ArrowPreviousIcon ref={navigationPrevRef} />
          <ArrowNextIcon ref={navigationNextRef} />
        </div>

        <Swiper
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          modules={[Navigation, EffectFade]}
          slidesPerView={
            props.projectInfo[props.selectedProjectIndex].slidesperview
          }
          className="project-swiper"
          spaceBetween={10}
          onSwiper={(swiper) => {
            swiper.params.navigation.prevEl = navigationPrevRef.current;
            swiper.params.navigation.nextEl = navigationNextRef.current;
            swiper.navigation.init();
            swiper.navigation.update();
          }}
        >
          {props.projectInfo[props.selectedProjectIndex].images.map(function (
            image,
            index
          ) {
            return (
              <SwiperSlide key={index}>
                <img className="project-img" src={image} alt="art" />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};

export default ProjectSlider;
