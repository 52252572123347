import React from "react";

const Icon = React.forwardRef((props, ref) => {
  return (
    <svg
      id="Layer_1"
      version="1.1"
      viewBox="0 0 64 64"
      ref={ref}
      style={{ cursor: "pointer" }}
    >
      <g>
        <g id="Icon-Chevron-Left" transform="translate(237.000000, 335.000000)">
          <polyline
            className="st0"
            id="Fill-35"
            points="-199.1,-289 -212.9,-302.7 -199.1,-316.4 -197.1,-314.4 -208.9,-302.7 -197.1,-291      -199.1,-289    "
          />
        </g>
      </g>
    </svg>
  );
});

export default Icon;