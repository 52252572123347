// components/CustomCursor.js
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import './CustomCursor.scss';

const CustomCursor = () => {
  const [cursorVariant, setCursorVariant] = useState('default');
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (e) => {
      setMousePosition({ x: e.clientX, y: e.clientY });
    };

    const handleCursorChange = (e) => {
      setCursorVariant(e.detail);
    };

    window.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('cursorChange', handleCursorChange);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('cursorChange', handleCursorChange);
    };
  }, []);

  const variants = {
    default: {
      x: mousePosition.x - 16,
      y: mousePosition.y - 16,
      backgroundColor: 'transparent',
      mixBlendMode: 'difference',
      width: 32,  // Default size
      height: 32, // Default size
    },
    h1Hover: {
      x: mousePosition.x - 16,
      y: mousePosition.y - 16,
      backgroundColor: 'blackå',
      mixBlendMode: 'difference',
      width: 32,  // Size for h1 hover
      height: 32, // Size for h1 hover
    },
    aHover: {
      width: 80,  // Size for link hover
      height: 80, // Size for link hover
      x: mousePosition.x - 40, // Adjust position for smaller size
      y: mousePosition.y - 40, // Adjust position for smaller size
      backgroundColor: 'black',
      mixBlendMode: 'difference',
    },
  };

  return (
    <motion.div
      className="cursor"
      variants={variants}
      animate={cursorVariant}
    />
  );
};

export default CustomCursor;
