import React from "react";
import { NavLink } from "react-router-dom";

const MenuSidebar = ({ handleNavLinkClick }) => {
  return (
    <div className="sidebar-item">
      <div className="container container-medium">
        <div className="row">
          <div className="col-md-12 ">
            <ul className="list-unstyled">
              <NavLink
                to="/work"
                className="nav-link"
                onClick={handleNavLinkClick}
              >
                <li className="list-unstyled fond-bold font-size-70 ">
                  <span className="font-size-14 font-regular mr-3">01.</span>
                  Work
                </li>
              </NavLink>
              <NavLink
                to="/about"
                className="nav-link"
                onClick={handleNavLinkClick}
              >
                <li className="list-unstyled font-bold font-size-70">
                  <span className="font-size-14 font-regular mr-3 ">02.</span>
                  About
                </li>
              </NavLink>
              <NavLink
                to="/contact"
                className="nav-link"
                onClick={handleNavLinkClick}
              >
                <li className="list-unstyled font-size-70">
                  <span className="font-size-14 font-regular mr-3">03.</span>
                  Contact
                </li>
              </NavLink>
            </ul>
            <div className="col-auto">
              <div className="social-links">
                <a href="https://www.instagram.com/harismourelatos/" target="_blank" className="font-size-20 btn pr-3 text-capitalize">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-arrow-up-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"
                    />
                  </svg>
                  instagram
                </a>
                <a href="https://www.facebook.com/mourelatos.haris" target="_blank" className="font-size-20 btn text-capitalize">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-arrow-up-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"
                    />
                  </svg>
                  facebook
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuSidebar;
