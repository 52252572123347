import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Home from "./components/Home";
import About from "./components/About";
import Projects from "./components/Projects";
import Work from "./components/Work";
import Footer from "./components/Footer";
import Contact from "./components/Contact";
import "./app.scss";
import { useSpring } from "@react-spring/web";
import CustomCursor from "./components/CustomCursor";

const App = () => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 992);

  const [animatedProps, setAnimatedProps] = useSpring(() => ({
    transform: `translate3d(0px, 0px, 0)`,
  }));

  // Handle resize events to update the isDesktop state
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 992);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Router>
      <Header />
      <div className="content-main">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/work" element={<Work />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </div>
      {/* Conditionally render CustomCursor based on isDesktop */}
      {isDesktop && <CustomCursor />}
      <div className="noise"></div>
      {/* <Footer /> */}
    </Router>
  );

  function handleMouseMove(event) {
    setAnimatedProps({
      transform: `translate3d(${event.clientX}px, ${event.clientY}px, 0)`,
    });
  }
};

export default App;