

import React from "react";

const Icon = React.forwardRef((props, ref) => {
  return (
    <svg
      id="Layer_1"
      version="1.1"
      viewBox="0 0 64 64"
      ref={ref}
      style={{ cursor: "pointer" }}
    >
      <g>
        <g id="Icon-Chevron-Left" transform="translate(237.000000, 335.000000)">
          <polyline
            className="st0"
            id="Fill-35"
            points="-210.9,-289 -212.9,-291 -201.1,-302.7 -212.9,-314.4 -210.9,-316.4 -197.1,-302.7      -210.9,-289    "
          />
        </g>
      </g>
    </svg>
  );
});

export default Icon;
