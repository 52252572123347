// Home.js
import React from 'react';
import './HeroSlider.scss';
import '../app.scss';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const Home = () => {
  const h1Enter = () => {
    document.dispatchEvent(new CustomEvent('cursorChange', { detail: 'h1Hover' }));
  };

  const h1Leave = () => {
    document.dispatchEvent(new CustomEvent('cursorChange', { detail: 'default' }));
  };

  const aEnter = () => {
    document.dispatchEvent(new CustomEvent('cursorChange', { detail: 'aHover' }));
  };

  const aLeave = () => {
    document.dispatchEvent(new CustomEvent('cursorChange', { detail: 'default' }));
  };

  return (
    <div>
      <div className="App main-title">
        <div className="waypoint-animate animate-top">
          <h1
            className="text-uppercase  text-center"
            onMouseEnter={h1Enter}
            onMouseLeave={h1Leave}
          >
            Hey, I’M Haris Mourelatos
          </h1>
          <h2
            onMouseEnter={h1Enter}
            onMouseLeave={h1Leave}
            className="text-center"
          >
            I’m a Full Stack <span className='highlight'>Developer</span>
          </h2>
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-center position-relative">
                <Link
                  to="/work"
                  className="font-size-20 btn pr-3 underline-hover text-capitalize text-white"
                  onMouseEnter={aEnter}
                  onMouseLeave={aLeave}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-arrow-up-right arrow-icon"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"
                    />
                  </svg>
                  my projects
                </Link>
                <Link
                  to="/about"
                  className="font-size-20 btn underline-hover text-capitalize text-white"
                  onMouseEnter={aEnter}
                  onMouseLeave={aLeave}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-arrow-up-right arrow-icon"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"
                    />
                  </svg>
                  about me
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;