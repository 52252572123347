import React, { useState, useEffect } from "react";
import "./Work.scss";

// Custom cursor event handlers
const aEnter = () => {
  document.dispatchEvent(new CustomEvent('cursorChange', { detail: 'aHover' }));
};

const aLeave = () => {
  document.dispatchEvent(new CustomEvent('cursorChange', { detail: 'default' }));
};

const Work = () => {
  const projects = [
    {
      id: 1,
      image: "trueopulence-1.jpg",
      title: "True Opulence",
      description: "True Opulence specializes in luxurious, high-end marketing campaigns for an elite clientele.",
      design: "Haris Mourelatos",
      text: "Haris mourelatos",
      link: "https://trueopulence.com/",
    },
    {
      id: 2,
      image: "healthnet.jpg",
      title: "Healthnet",
      description: "Healthnet provides comprehensive healthcare services and solutions.",
      design: "Haris Mourelatos",
      text: "Haris mourelatos",
      link: "https://healthnet.com.gr/",
    },
    {
      id: 3,
      image: "invest.jpg",
      title: "Invest in the Future",
      description: "Invest in the future of Greek Shipping. Real Time Graduates is a non-profit platform that connects graduates with the Greek shipping industry.",
      design: "Haris Mourelatos",
      text: "Haris mourelatos",
      link: "https://investinthefuture.gr/",
    },
    {
      id: 4,
      image: "crm-trueopulence.jpg",
      title: "CRM - True Opulence",
      description: "CRM for managing client interactions and data.",
      design: "Haris Mourelatos",
      text: "Haris mourelatos",
      link: "https://hub.trueopulence.com/login",
    },
    // {
    //   id: 5,
    //   image: "gyn4me.jpg",
    //   title: "Gyn4Me",
    //   description: "Gyn4Me provides resources and support for gynecological health.",
    //   design: "Haris Mourelatos",
    //   text: "Haris mourelatos",
    //   link: "https://gyn4me.gr/",
    // },
    {
      id: 5,
      image: "ebeis.jpg",
      title: "Ebeis",
      description: "Ebeis offers a range of innovative solutions in various sectors.",
      design: "Haris Mourelatos",
      text: "Haris mourelatos",
      link: "https://ebeis.gr/",
    },
    {
      id: 6,
      image: "mykonos.jpg",
      title: "Mykonos Elia Yachting",
      description: "Luxury yachting experiences in Mykonos.",
      design: "Haris Mourelatos",
      text: "Haris mourelatos",
      link: "https://mykonoseliayachting.com/",
    },
    {
      id: 7,
      image: "welmed.jpg",
      title: "Welmed",
      description: "Welmed specializes in medical and wellness solutions.",
      design: "Haris Mourelatos",
      text: "Haris mourelatos",
      link: "https://welmed.gr/",
    },
    {
      id: 8,
      image: "adproperties.jpg",
      title: "Adproperties",
      description: "Adproperties offers real estate solutions and services.",
      design: "Haris Mourelatos",
      text: "Haris mourelatos",
      link: "https://adproperties.gr/",
    },
    {
      id: 9,
      image: "vagmour.jpg",
      title: "Vagmour",
      description: "Vagmour provides innovative products and services.",
      design: "Haris Mourelatos",
      text: "Haris mourelatos",
      link: "http://vagmour.com/",
    },
     {
      id: 10,
      image: "endion.jpg",
      title: "Endion",
      description: "endion",
      design: "Haris mourelatos",
      text: "Haris mourelatos",
      link: "https://endion.com.gr/",
    },
  ];

  const [activeImage, setActiveImage] = useState("");

  useEffect(() => {
    // Set the active image to the image of the first project
    setActiveImage(projects[0].image);
  }, []);

  const handleTitleHover = (image) => {
    setActiveImage(image);
  };

  const handleMouseEnter = (image) => {
    handleTitleHover(image);
    aEnter(); // Call aEnter to apply the cursor change for link hover
  };

  const handleMouseLeave = () => {
    setActiveImage("");
    aLeave(); // Call aLeave to reset the cursor
  };

  return (
    <section id="work">
      <div className="container container-xl pl-xl-0">
        <div className="row mb-4">
          <div className="col-lg-7 project-image d-none d-md-block">
            <div className="h-100">
              <img
                src={`../images/grid/${activeImage}`}
                className={`img-fluid h-100${activeImage ? " active" : ""}`}
                alt="Active Project"
              />
            </div>
          </div>
          <div className="col-lg-5">
            <ul className="list-unstyled">
              <li>
                <div className="row no-gutters mb-4 projects-row">
                  <div className="col-md-6 col-6 px-0 mb-2">
                    <h1 className="font-size-32 mb-0">
                      Work
                    </h1>
                  </div>
                  <div className="col-md-6 col-6 d-flex mb-2 justify-content-end">
                    <div className="mb-0 font-size-32">{projects.length}</div>
                  </div>
                </div>
              </li>
              {projects.map((project, index) => (
                <li key={index} className="mb-3">
                  <a
                    href={project.link}
                    target="_blank"
                    className="text-capitalize project-link fw-bold font-size-20 text-white"
                    onMouseEnter={() => handleMouseEnter(project.image)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <div className="row no-gutters mb-4 projects-row">
                      <div className="col-md-6 col-6 px-0 mb-2">
                        <div className="projects-selected-wrapper">
                          <div className="projects-selected fw-bold font-size-20">
                            →
                          </div>
                        </div>
                        <div className="project-title project-delay">
                          {project.title}
                        </div>
                      </div>
                      <div className="col-md-6 col-6 px-0 mb-2 d-flex justify-content-end project-dev project-delay">
                        Web development
                      </div>
                    </div>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Work;
